import { type SerializedStyles } from '@emotion/react'
import { encodeQuery } from 'fitify-ui-onboarding'
import {
  LayoutContent,
  LayoutStack,
  SubscriptionPromoInfoBoxStyled,
  toast,
} from 'fitify-ui-onboarding/src/components'
import Header from 'fitify-ui-onboarding/src/components/Header/Header'
import { useAuth } from 'fitify-ui-onboarding/src/hooks/useAuth'
import { useBeforeLeaveDialog } from 'fitify-ui-onboarding/src/hooks/useBeforeLeaveDialog'
import { getPaging } from 'fitify-ui-onboarding/src/hooks/useNavigation'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'
import { ReactNode, useContext } from 'react'

import { getImageProps } from 'utils/images'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'
import { DataContext } from 'utils/state'

interface LayoutProps {
  seo?: {
    title?: string
    description?: string
    openGraph?: OpenGraph
  }
  options?: {
    isFullscreen?: boolean
    isPrevHidden?: boolean
    isHeaderHidden?: boolean
  }
  layoutStyle?: SerializedStyles
  children: ReactNode
}

const SubscriptionPromoInfoBox = () => {
  const { t } = useTranslation()

  return (
    <SubscriptionPromoInfoBoxStyled>
      {t('promo_info_active_title')}
    </SubscriptionPromoInfoBoxStyled>
  )
}

export function Layout({ options, layoutStyle, children, seo }: LayoutProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const promoCode = usePromo()

  const { user, logout } = useAuth()
  const { data, clear } = useContext(DataContext)

  const handleLogout = async () => {
    try {
      await logout()
      clear() // clear state and promo

      router.replace('/')
    } catch (err) {
      console.error(err)
      toast({ type: 'error', content: 'Failed to log out' })
    }
  }

  const noLeaveDialogPages = [
    ONBOARDING_PAGES.home.path,
    ONBOARDING_PAGES.subscription.path,
    ONBOARDING_PAGES.success.path,
    APP_PAGES.login.path,
    APP_PAGES.loginEmail.path,
    APP_PAGES.signup.path,
    APP_PAGES.passwordReset.path,
    APP_PAGES.giftSuccess.path,
    '/404',
    '/500',
  ]

  useBeforeLeaveDialog(!noLeaveDialogPages.includes(router.route))

  const handleOnPrevClick = () => {
    const paging = getPaging({
      path: router.pathname,
      data,
    })

    if (paging.prev) {
      // Preserve query parameters
      const query = router.query as NodeJS.Dict<string>
      router.replace(
        encodeQuery(paging.prev.path, {
          ...query,
        })
      )
    } else {
      router.back()
    }
  }

  const noPromoPages = [
    ONBOARDING_PAGES.subscription.path,
    ONBOARDING_PAGES.success.path,
    APP_PAGES.gift.path,
    APP_PAGES.giftSuccess.path,
    APP_PAGES.redeem.path,
    APP_PAGES.redeemSuccess.path,
    '/404',
    '/500',
  ]

  const isPromoActive =
    promoCode !== null && !noPromoPages.includes(router.route)

  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={seo?.openGraph}
      />
      {!options?.isHeaderHidden && (
        <Header
          components={{
            logo: getImageProps('common', 'logo'),
          }}
          data={{
            user: user,
            promo: promoCode,
            logoutText: t('hc_logout_title'),
          }}
          actions={{
            handleLogout,
            handleOnPrevClick,
          }}
          options={{
            isPrevHidden: options?.isPrevHidden,
          }}
        />
      )}

      <LayoutStack orientation="horizontal" xs="5.125rem">
        <LayoutContent isFullscreen={options?.isFullscreen} css={layoutStyle}>
          {isPromoActive && <SubscriptionPromoInfoBox />}
          {children}
        </LayoutContent>
      </LayoutStack>
    </>
  )
}
