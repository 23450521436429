import {
  ContentContainer,
  Heading,
  HeadingContainer,
  PageContainer,
  Stack,
  StyledPrimaryButton,
  StyledSecondaryButton,
  Text,
  Threshold,
} from 'fitify-ui-onboarding/src/components'
import NativeImage from 'fitify-ui-onboarding/src/components/Image/NativeImage'
import { useAuth } from 'fitify-ui-onboarding/src/hooks/useAuth'
import {
  getServerSideMetaData,
  MetaDataProps,
} from 'fitify-ui-onboarding/src/utils/meta-tags'
import { encodeQuery } from 'fitify-ui-onboarding/src/utils/query'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Layout } from 'components/Layout'
import { PageTitle } from 'components/PageTitle'
import { getStaticPropsTranslations } from 'utils/i18n'
import { getImageProps } from 'utils/images'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const locale = context.locale || 'en'
  const promoCode = context.query.promo as string

  return {
    props: {
      ...(await getStaticPropsTranslations(locale)),
      metaData: getServerSideMetaData(promoCode),
    },
  }
}

export default function Page({ metaData }: { metaData: MetaDataProps }) {
  const { t } = useTranslation()
  const router = useRouter()
  const { user } = useAuth()

  const handleContinue = () => {
    router.push(ONBOARDING_PAGES.gender.path)
  }

  const handleLogin = () => {
    router.push(
      encodeQuery(APP_PAGES.login.path, { next: ONBOARDING_PAGES.gender.path })
    )
  }

  return (
    <Layout
      options={{
        isPrevHidden: true,
      }}
      seo={{
        description: t('onboarding_home_meta_description'),
      }}
    >
      <PageTitle
        title={t('hc_onboarding_welcome_page_title')}
        metaData={metaData}
      />
      <PageContainer>
        <ContentContainer xs="1.5rem" md="2rem" removePadding>
          <Stack xs="0.5rem">
            <HeadingContainer>
              <Heading>{t('hc_onboarding_welcome_main_title')}</Heading>
            </HeadingContainer>

            <Text color="gray700">
              {t('hc_onboarding_welcome_main_description_v1')}
            </Text>
          </Stack>

          <NativeImage
            alt={'Find perfect coach'}
            {...getImageProps('home', 'coachesGroup')}
            isResponsive
          />
        </ContentContainer>

        <Threshold hasBackground>
          <Stack xs={24} md={40} align="center">
            <StyledPrimaryButton onClick={handleContinue}>
              {t('btn_continue')}
            </StyledPrimaryButton>

            {!user && (
              <Stack xs={8} md={16} align="center">
                <Text>{t('welcome_have_account_question')}</Text>
                <StyledSecondaryButton size="md" onClick={handleLogin}>
                  {t('email_login_forgot_btn_sign_in')}
                </StyledSecondaryButton>
              </Stack>
            )}
          </Stack>
        </Threshold>
      </PageContainer>
    </Layout>
  )
}
